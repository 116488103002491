<script>

import LeftNavigation from "@/components/LeftNavigation.vue";

export default {
  components: {LeftNavigation}
}
</script>

<template>
  <div class="black_list">
    <LeftNavigation :menu="[
      {name: 'black', ti: '客户黑名单'},
      {name: 'dict', ti: '数据字典'},
      {name: 'expenses', ti: '费用项'}
    ]"></LeftNavigation>
    <section>
      <router-view></router-view>
    </section>
  </div>
</template>

<style scoped lang="less">
.black_list{
  display: flex;
  height: 100%;
  overflow: hidden;
}
section{
  box-sizing: border-box;
  width: calc(100% - 200px);
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}
</style>
